.test-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  
  .test-page-content-wrapper {
    width: 1000px;
    
    .options-wrapper {
      font-size: 15px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      .test-select {
        margin-left: 10px;
        font-size: 15px;
      }
    }

    .iframe-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .chart-tittle {
        padding: 50px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .test-page-content-wrapper {
  
    width: 95% !important;
    }
  }
}
